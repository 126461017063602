import React from 'react';
import LandingPage from './pages/LandingPage.js';

function App() {
  return (
    <div>
      <LandingPage />
      {/* Add other components here */}
    </div>
  );
}

export default App;

